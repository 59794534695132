import http from "@/utils/http";

/**
 * 提交预约
 * @param phone
 */
export function appointSubmit(data){
    return http({
        url: "app/user/appointment",
        method: "post",
        data:data
    })
}

/**
 * 我的预约
 */
export function myAppoint(data){
    return http({
        url:'app/user/appointment/list',
        method:'post',
        data:data
    })
}

/**
 * 取消预约
 * @param id
 * @returns {AxiosPromise}
 */
export function cancelAppointment(id){
    return http({
        url:'app/user/cancel/appointment/'+id,
        method:'get'
    })
}

<template>
  <div>
  <van-nav-bar left-arrow title="忘记密码" @click-left="goBack"/>
    <van-form >
      <van-field
          v-model="phone"
          name="phone"
          label="手机号码"
          center
          required
          clearable
          placeholder="请输入手机号" maxlength="15"/>
      <van-field
          v-model="code"
          center
          required
          clearable
          label="短信验证码"
          placeholder="请输入短信验证码">
        <template #button>
          <van-button size="small" :disabled="btDisabled" type="primary" @click="sendSms" :text="msg"></van-button>
        </template>
      </van-field>
      <van-field
          v-model="pwd"
          name="pwd"
          label="登录密码"
          required
          clearable
          center
          type="password"
          placeholder="请输入6-16位登录密码"
          maxlength="16"/>
      <van-field
          v-model="confirmPwd"
          name="confirmPwd"
          label="确认密码"
          required
          clearable
          center
          type="password"
          placeholder="请输入6-16位登录密码"
          maxlength="16"/>
      <div style="margin: 16px;">
        <van-button round block type="info" native-type="submit" @click="handle">确认修改</van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import {sendSms,resetPwd} from '@/api/user'
import {Toast} from "vant";
export default {
  name: "forget.vue",
  data(){
    return{
      phone:'',
      pwd:'',
      confirmPwd:'',
      code:'',
      msg:'发送验证码',
      time:null,
      btDisabled:false
    }
  },
  methods:{
    sendSms(){
      let reg=/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      if(this.phone=='' || !reg.test(this.phone)){
        Toast({
          message: '手机号格式不正确',
          icon:'warning-o',
          overlay:true,
          forbidClick: true,
        });
        return;
      }
      const TIME_COUNT = 60;
      sendSms(this.phone).then(res=> {
        if("200" != res.data.code){
          Toast({
            message: '短信发送失败',
            icon:'warning-o',
            overlay:true,
            forbidClick: true,
          });
          return;
        }
        if (!this.timer) {
          this.count = TIME_COUNT;
          this.btDisabled = true;
          this.timer = setInterval(() => {
            if (this.count > 0 && this.count <= TIME_COUNT) {
              this.count--;
              this.msg = "已发送("+this.count+")";
            } else {
              this.msg = "发送验证码";
              this.btDisabled = false;
              clearInterval(this.timer);
              this.timer = null;
            }
          }, 1000)
        }
      })
    },
    handle(){
      if(this.phone == '' || this.pwd =='' || this.code == ''){
        Toast({
          message: '请填写完整的信息',
          icon:'warning-o',
          overlay:true,
          forbidClick: true,
        });
        return;
      }
      if(this.pwd != this.confirmPwd){
        Toast({
          message: '两次密码不一致',
          icon:'warning-o',
          overlay:true,
          forbidClick: true,
        });
        return;
      }
      let data = {
        appPhone:this.phone,
        password:this.pwd,
        rePassword:this.confirmPwd,
        code:this.code
      }
      resetPwd(data).then(res => {
        if("200" != res.data.code){
          Toast({
            message: res.data.msg,
            icon:'warning-o',
            overlay:true,
            forbidClick: true,
          });
          return;
        }
        Toast({
          message: '修改成功',
          icon:'success',
          overlay:true,
          forbidClick: true
        });
        let _this = this;
        setTimeout(function(){
          _this.$router.push("/login");
        },2000)
      })
    },
    goBack(){
      this.$router.go(-1);
    }
  }
}
</script>

<style scoped>

</style>

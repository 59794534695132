<template>
  <div>
    <van-nav-bar title="我的车辆" left-arrow left-text="返回"  @click-left="goHome"/>
    <van-empty v-if="vehicleList.length == 0"  image="search"  description="您还未绑定车辆" />
    <div class="contains">
      <div class="box" v-for="vehicle in vehicleList" v-bind:key="vehicle.id">
        <div class="icon">
          <van-image :src="require('../../assets/vehicle.png')" height="40"/>
        </div>
        <div class="info">
          <div class="brand" v-text="vehicle.plateNo"></div>
          <div class="vin" v-text="vehicle.frameNo"></div>
        </div>
        <div class="tools">
          <van-button class="bt" icon="close" size="small" color="#ff5050" type="primary" @click="handleUnbind(vehicle.id)">解绑</van-button>
        </div>
      </div>
      <van-button round block type="info" icon="plus" @click="gotoAdd">绑定新车辆</van-button>
    </div>

  </div>
</template>

<script>
import {myVehicle,removeVehicle} from '@/api/vehicle';
import {Dialog, Toast} from "vant";
export default {
  name: "my",
  data(){
    return{
      vehicleList:[]
    }
  },
  mounted() {
    this.getMyVehicle();
  },
  methods:{
    gotoAdd(){
      this.$router.push('/vehicle/add')
    },
    goHome(){
      this.$router.push('/member')
    },
    getMyVehicle(){
      myVehicle().then(res => {
        if("200" != res.data.code){
          Toast({
            message: res.data.msg,
            icon:'warning-o',
            overlay:true,
            forbidClick: true,
          });
          return;
        }
        let list = res.data.data;
        if(list){
          this.vehicleList = list;
        }
      })
    },
    handleUnbind(id){
      Dialog.confirm({
        title: '系统提示',
        confirmButtonColor:'#0099ff',
        message: '确定要解绑此车辆吗?',
        theme: 'round-button',
      }).then(() => {
        removeVehicle(id).then(res => {
          if("200" != res.data.code){
            Toast({
              message: res.data.msg,
              icon:'warning-o',
              overlay:true,
              forbidClick: true,
            });
            return;
          }
          Toast({
            message: '成功解绑',
            icon:'success',
            overlay:true,
            forbidClick: true
          });
          this.getMyVehicle();
        })
      }).catch(() => {
      });
    }
  }
}
</script>

<style scoped>
.box{
  width: 100%;
  height: 100px;
  background-color: #FFFFFF;
  border-radius: 5px;
  line-height: 100px;
  vertical-align: middle;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 10px;
}
.box .icon{
  width: 100px;
  padding-left: 20px;
  line-height: 80px;
  padding-top: 20px;
}
.box .info{
  width: 100%;
  height: 70px;
  margin-left: 20px;
  line-height: 70px;
  vertical-align: middle;
}
.box .info .brand{
  height: 30px;
  font-size: 18px;
  font-weight: bolder;
}
.box .info .vin{
  height: 30px;
  font-size: 14px;
  color: darkgrey;
}
.box .tools{
  width: 150px;
  height: 80px;
  margin-left: 20px;
  line-height: 80px;
  vertical-align: middle;
  padding-top: 10px;
  padding-right: 10px;
  display: flex;
  flex-direction: column;
}
.box .tools .bt{
  top:5px;
  right:10px;
  margin-left: 10px;
  margin-top: 10px;
}
</style>

<template>
<div>
  <van-nav-bar title="会员中心"/>
  <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
    <div class="bg">
      <div class="icon">
        <van-image
            round
            width="70px"
            height="70px"
            :src="require('../../assets/member.png')"/>
      </div>
      <div class="member" v-if="user.id!=null">
        <div class="name" v-text="user.name"></div>
        <div class="phone" v-text="user.phone"></div>
      </div>
      <div class="member" v-else>
        <div class="noLogin">您还没有未登录</div>
        <div class="gotoLogin"><van-button round color="#ff7733" type="info" size="small">立即登录</van-button></div>
      </div>
    </div>
    <van-grid :column-num="2">
      <van-grid-item>
        <div class="stat">
          <div class="total" v-text="user.repairCount"></div>
          <div class="title">维修次数(次)</div>
        </div>
      </van-grid-item>
      <van-grid-item>
        <div class="stat">
          <div class="total" v-text="user.totalPrice"></div>
          <div class="title">维修花费(元)</div>
        </div>
      </van-grid-item>
    </van-grid>
    <van-cell title="M站查询" is-link @click="goto('/station/index')">
      <template #title>
        <van-icon name="wap-home-o" color="#ff7733" size="20"/>
        <span class="menu-title">M站查询</span>
      </template>
    </van-cell>
    <van-cell title="我的车辆" is-link @click="goto('/vehicle/my')">
      <template #title>
        <van-image class="menu-icon" :src="require('../../assets/car.png')" width="20px" height="20px"/>
        <span class="menu-title">我的机动车</span>
      </template>
    </van-cell>
    <van-cell title="我的预约" is-link @click="goto('/appoint/list')">
      <template #title>
        <van-icon name="todo-list-o" color="#ff7733" size="20"/>
        <span class="menu-title">我的预约</span>
      </template>
    </van-cell>
    <van-cell title="我的维修" is-link @click="goto('/repair/list')">
      <template #title>
        <van-icon name="balance-list-o" color="#ff7733" size="20"/>
        <span class="menu-title">我的维修</span>
      </template>
    </van-cell>
<!--    <van-cell title="绑定手机号" is-link>-->
<!--      <template #title>-->
<!--        <van-icon name="phone-o" color="#ff7733" size="20"/>-->
<!--        <span class="menu-title">绑定手机号</span>-->
<!--      </template>-->
<!--    </van-cell>-->
    <van-cell title="联系客服" is-link @click="showService">
      <template #title>
        <van-icon name="user-o" color="#ff7733" size="20"/>
        <span class="menu-title">联系我们</span>
      </template>
    </van-cell>
  </van-pull-refresh>
    <van-button class="exit" block type="info" @click="logout()">退出登录</van-button>
</div>
</template>

<script>
import {getUserInfo} from "@/api/user";
import {Dialog, Toast} from 'vant';
export default {
  name: "memberHome",
  data(){
    return{
      isLoading:false,
      imgSrc:require('../../assets/bg_member.png'),
      user:{
        id:'',
        name:'',
        phone:'',
        repairCount:0,
        totalPrice:0
      }
    }
  },
  mounted() {
    Toast.clear();
    this.userInit();
  },
  methods:{
    userInit(){
      let user = localStorage.getItem("im_user");
      if(user == null){
        this.user = {
          id:'',
          name:'',
          phone:'',
          repairCount:0,
          totalPrice:0
        }
        let _this = this;
        Dialog.confirm({
          title: '系统提示',
          message: '您还没有登录，是否立即登录',
        }).then(() => {
          _this.$router.push({
            path:"/login",
          })
        }).catch(() => {
        });
        return;
      }else{
        let userObj = JSON.parse(user);
        let phone = userObj.userPhone.substr(0, 3) + '****' + userObj.userPhone.substr(7);
        this.user = {
          id:userObj.userId,
          name:userObj.username,
          phone:phone,
          repairCount:userObj.repairCount,
          totalPrice:userObj.totalPrice
        }
      }

    },
    onRefresh() {
      this.getUserInfo();
      this.isLoading = false;
    },
    goto(url){
      this.$router.push(url);
    },
    showService(){
      Dialog.confirm({
        title: '联系我们',
        confirmButtonColor:'#0099ff',
        message: '客服电话：15053386957',
        theme: 'round-button',
      }).then(() => {
        window.location.href = 'tel://' + 15053386957;
      }).catch(() => {
      });
    },
    getUserInfo(){
      Toast.loading({
        message: '正在获取用户信息...',
        forbidClick: true,
        loadingType: 'spinner'
      });
      getUserInfo().then(res => {
        Toast.clear();
        if("200" != res.data.code){
          Toast({
            message: '获取用户信息失败',
            icon:'warning-o',
            overlay:true,
            forbidClick: true,
          });
          return;
        }
        let data = res.data.data;
        let user = {
          userId:data.userId,
          username:data.username,
          totalPrice:data.totalPrice,
          userPhone:data.userPhone,
          repairCount:data.repairCount,
        }
        localStorage.setItem("im_user",JSON.stringify(user));
      })
    },
    logout(){
      Dialog.confirm({
        title: '系统提示',
        message: '是否确认退出',
      }).then(() => {
            localStorage.removeItem("im_user");
            localStorage.removeItem("im_token");
            this.$router.push("/");
      }).catch(() => {
      });
    }
  }
}
</script>

<style scoped>
.bg {
  width: 100%;
  height: 135px;
  background: url('../../assets/bg_member.png') no-repeat;
  background-size: 100% auto;
  z-index: -99;
  display: flex;
  flex-direction: row;
  justify-content: left;
}
.bg .icon{
  width: 100px;
  position:relative;
  top:30px;
  left: 20px;
}
.bg .member{
  position:relative;
  top:30px;
  color: #f4f4f4;
}
.bg .member .name{
  line-height: 25px;
  font-size: 18px;
  font-weight: bolder;
}
.bg .member .phone{
  line-height: 25px;
  font-size: 14px;
}
.bg .member .noLogin{
  line-height: 30px;
  font-size: 18px;
  font-weight: bolder;
}
.bg .member .gotoLogin{
  margin-top: 5px;
}
.menu-title{
  margin-left: 5px;
}
.menu-icon{
  position: relative;
  top:5px;
}
.exit{
  position: fixed;
  bottom: 0;
}
.stat{
  text-align: center;
}
.stat .title{
  font-size: 14px;
  line-height: 20px;
}
.stat .total{
  font-size: 30px;
  line-height: 50px;
  color: #0099ff;
}
</style>

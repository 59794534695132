import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import BaiduMap from 'vue-baidu-map'
import axios from 'axios';
import VueAxios from 'vue-axios';
import Vant from 'vant';
import { PullRefresh,Dialog,Empty} from 'vant';
import { Toast,Tab,Tabs } from 'vant';
import 'vant/lib/index.css';
import '@/assets/commons.css'
Vue.config.productionTip = false
Vue.use(VueAxios, axios);
Vue.use(Vant);
Vue.use(PullRefresh);
Vue.use(Dialog);
Vue.use(Toast);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Empty);
Vue.use(BaiduMap, {
  ak: 'rUTfshMx0ja4pccx784OZ3zuIVnQTYhr'
})
new Vue({
  router,
  render: h => h(App)
}).$mount("#app")

import http from "@/utils/http";

/**
 * 绑定车辆
 * @param phone
 */
export function addVehicle(data){
    return http({
        url: "/app/vehicleinfo/save",
        method: "post",
        data:data
    })
}

/**
 * 绑定车辆
 * @param phone
 */
export function myVehicle(){
    return http({
        url: "/app/vehicleinfo/get",
        method: "get"
    })
}

/**
 * 解除绑定
 * @param phone
 */
export function removeVehicle(id){
    return http({
        url: "/app/vehicleinfo/remove/"+id,
        method: "delete"
    })
}


import axios from "axios";
// import store from '../store';
// import router from '../router';
let BASE_URL = "https://m.azdzkj.cn/prod-api/";
// let BASE_URL = "http://localhost:9000/";
const http = axios.create({
  baseURL: BASE_URL,
  timeout: 60000,
  headers: {
    "Content-Type": "application/json",
    "X-Requested-With": "XMLHttpRequest"
  }
  // withCredentials: true
});

/* 请求拦截器 */
http.interceptors.request.use((config) => {
  // 添加token到header
  let token = localStorage.getItem("im_token");
  if (token) {
    config.headers['account_token'] = token;
  }
  return config;
}, function (error) {
  return Promise.reject(error);
});

/* 响应拦截器 */
http.interceptors.response.use((res) => {
  // 登录过期处理
  if (res.data.code === 401) {
    // if (res.config.url === setting.menuUrl) {
    //   // goLogin();
    // } else {
    //   Modal.destroyAll();
    //   Modal.info({
    //     title: '系统提示',
    //     content: '登录状态已过期, 请退出重新登录!',
    //     okText: '重新登录',
    //     onOk: () => {
    //       goLogin(true);
    //     }
    //   });
    // }
    return Promise.reject(new Error(res.data.msg));
  }
  // token自动续期
  // let access_token = res.headers[setting.tokenHeaderName];
  // if (access_token) {
  //   setting.cacheToken(access_token);
  // }
  return res;
}, (error) => {
  return Promise.reject(error);
});


// function goLogin(reload) {
//   store.dispatch('user/removeToken').then(() => {
//     if (reload) {
//       location.replace('/login');  // 这样跳转避免再次登录重复注册动态路由
//     } else {
//       const path = router.currentRoute.path;
//       router.push({
//         path: '/login',
//         query: path && path !== '/' ? {form: path} : null
//       });
//     }
//   });
// }

export default http;

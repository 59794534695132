import http from "@/utils/http";

/**
 * 维修站查询
 * @param phone
 */
export function queryM(data){
    return http({
        url: "app/service/centre/list",
        method: "post",
        data:data
    })
}

/**
 * 维修站详情
 * @param id
 */
export function mInfo(id){
    return http({
        url:"app/service/centre/info/"+id,
        method: "get"
    })
}

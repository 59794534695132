import http from "@/utils/http";

/**
 * 发送短信验证码
 * @param phone
 */
export function sendSms(phone){
    return http({
        url: "app/getcode",
        method: "get",
        params:{
            phone:phone
        }
    })
}

/**
 * 注册
 * @param phone
 */
export function register(data){
    return http({
        url: "app/register",
        method: "post",
        data:data
    })
}



/**
 * 修改密码
 * @param data
 * @returns {AxiosPromise}
 */
export function resetPwd(data){
    return http({
        url:'app/reset/password',
        method:'post',
        data:data
    })
}

/**
 * 登录
 * @param data
 * @returns {AxiosPromise}
 */
export function login(data) {
    return http({
        url: "app/login",
        method: "post",
        data: data
    });
}

/**
 * 获取用户详情
 * @returns {AxiosPromise}
 */
export function getUserInfo(){
    return http({
        url:"app/user/info",
        method:'get'
    })
}


import http from "@/utils/http";

/**
 * 用户维修订单列表
 * @param phone
 */
export function queryRepair(data){
    return http({
        url: "app/user/repair/order",
        method: "post",
        data:data
    })
}

/**
 * 维修单详情
 * @param id
 * @returns {AxiosPromise}
 */
export function getRepairInfo(id){
    return http({
        url:'app/user/repair/order/det/'+id,
        method:'get'
    })
}

/**
 * 提交评价
 * @param data
 * @returns {AxiosPromise}
 */
export function evalSubmit(data){
    return http({
        url:'app/evaluation',
        method:'post',
        data:data
    })
}

import Vue from "vue";
import Router from "vue-router";
import Index from '../views/station/index';
import Info from '../views/station/info';
import MemberIndex from '../views/member/index'
import Login from '../views/member/login'
import Register from '../views/member/register'
import Forget from '../views/member/forget'
import AddVehicle from '../views/vehicle/add'
import MyVehicle from '../views/vehicle/my'
import AddAppoint from '../views/appoint/add'
import AppointList from '../views/appoint/list'
import RepairList from '../views/repair/list'
import RepairInfo from '../views/repair/info'
Vue.use(Router);

export default new Router({
    mode:'history',
    routes: [
        {
            path: '/station/index',
            name: 'index',
            component: Index
        },
        {
            path: '/station/info',
            name: 'info',
            component: Info
        },
        {
            path: '/member',
            name: 'member',
            component: MemberIndex
        },
        {
            path: '/',
            name:'login',
            component: Login
        },
        {
            path: '/login',
            name:'login',
            component: Login
        },
        {
            path: '/register',
            name:'register',
            component: Register
        },
        {
            path: '/forget',
            name:'forget',
            component: Forget
        },
        {
            path: '/vehicle/my',
            name: 'myVehicle',
            component: MyVehicle
        },
        {
            path: '/vehicle/add',
            name: 'addVehicle',
            component: AddVehicle
        },
        {
            path: '/appoint/add',
            name: 'addAppoint',
            component: AddAppoint
        },
        {
            path: '/appoint/list',
            name: 'appointList',
            component: AppointList
        },
        {
            path: '/repair/list',
            name: 'repairList',
            component: RepairList
        },
        {
            path: '/repair/info',
            name: 'repairInfo',
            component: RepairInfo
        }

    ]
});


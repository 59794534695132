<template>
  <div>
    <van-sticky>
    <van-nav-bar title="M站查询"/>
    <van-search
        v-model="query.sitemName"
        show-action
        placeholder="请输入M站名称"
        action-text="搜索"
        @search="handleSearch">
      <template #action>
        <div @click="handleSearch">搜索</div>
      </template>
    </van-search>
    <van-dropdown-menu >
      <van-dropdown-item v-model="query.orgCode" :options="orgList" @change="changeOrg"/>
      <van-dropdown-item v-model="query.sort" :options="sortList" @change="changeSort"/>
    </van-dropdown-menu>
    </van-sticky>
    <van-pull-refresh v-model="loading" @refresh="handleSearch">
    <van-list
        class="list"
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="queryM">
      <div class="box" v-for="m in mList" v-bind:key="m.id" @click="gotoStation(m.id)">
        <div class="icon">
          <van-image v-if="m.sitePhoto==''" :src="require('../../assets/m.png')" height="40"/>
          <van-image v-else :src="m.sitePhoto" height="40"/>
        </div>
        <div class="info">
          <div class="title" v-text="m.name"></div>
          <div class="address" v-text="m.address"></div>
          <div>
            <div class="station">
              <span class="station" v-text="'工位：'+m.station"></span>
              <span v-text="'营业时间：'+m.businessTime"></span>
              <br/>
            </div>
            <span class="score">
              <van-icon name="star" color="#ff9933"/>
              <label v-text="m.averageScore>0?m.averageScore:'3.0'"></label>
            </span>
            <van-tag type="primary">{{m.type}}</van-tag>
            <span class="distance" v-text="'距离：'+m.distance+'公里'"></span>
          </div>
        </div>
      </div>
    </van-list>
    </van-pull-refresh>
    <div id="allmap" ref="allmap"></div>
  </div>
</template>

<script>
import {queryM} from '@/api/station'
import {Toast} from "vant";
export default {
  name: "index.vue",
  data () {
    return {
      query:{
        sitemName:'',
        address:'',
        pageNum:1,
        pageSize:10,
        orgCode:'',
        sort:'score',
        longitude:'115.486191',
        latitude:'35.2401',
      },
      loading:false,
      finished: true,
      showAppoint:false,
      orgList:[
        {text:'全部M站',value:''},
        {text:'牡丹区',value:'371702'},
        {text:'定陶区',value:'371727'},
        {text:'曹县',value:'371721'},
        {text:'单县',value:'371722'},
        {text:'成武县',value:'371723'},
        {text:'巨野县',value:'371724'},
        {text:'郓城县',value:'371725'},
        {text:'鄄城县',value:'371726'},
        {text:'东明县',value:'371728'},
        {text:'经济技术开发区',value:'371740'},
        {text:'高新技术开发区',value:'371741'}
      ],
      sortList:[
        { text: '评分优先', value: 'score' },
        { text: '距离优先', value: 'distance' }
      ],
      mList:[]
    }
  },
  mounted() {
    this.getLocation();
  },
  methods: {
    changeOrg(value){
      this.query.org = value;
      this.handleSearch();
    },
    changeSort(value){
      this.query.sort = value;
      this.handleSearch();
    },
    getLocation(){
      Toast.loading({
        message: '正在加载...',
        overlay:true,
        forbidClick: true,
        duration:0
      });
      let _this = this;
      var geolocation = new window.BMapGL.Geolocation();
// 开启SDK辅助定位
      geolocation.enableSDKLocation();
      geolocation.getCurrentPosition(function(r){
        Toast.clear();
        if(this.getStatus() == window.BMAP_STATUS_SUCCESS){
          _this.query.longitude = r.point.lng;
          _this.query.latitude = r.point.lat;
          _this.handleSearch();
        }
        else {
          Toast({
            message: '获取您的当前地理位置失败',
            icon:'warning-o',
            overlay:true,
            forbidClick: true,
          });
        }
      });
    },
    handleSearch(){
      this.mList = [];
      this.query.pageNum = 1;
      this.queryM();
    },
    queryM(){
      this.loading = true;
      this.query.address = this.query.sitemName;
      queryM(this.query).then(res => {
        this.loading = false;
        if("200" != res.data.code){
          Toast({
            message: res.data.msg,
            icon:'warning-o',
            overlay:true,
            forbidClick: true,
          });
          return;
        }
        let total = res.data.data.total;
        let list = res.data.data.list;
        list.forEach(item =>{
          this.mList.push(item);
        })
        if(this.mList.length < total){
          this.finished = false;
          this.query.pageNum += 1;
        }else{
          this.finished = true;
        }
      })
    },
    gotoStation(id){
      this.$router.push({
        path:"/station/info",
        query:{
          id: id
        }
      })
    }
  }
}
</script>

<style scoped>
.list{
  padding: 10px 5px 10px 5px;
  background-color: #f4f4f4;
}
.box{
  height: 100px;
  background-color: #FFFFFF;
  border-radius: 5px;
  vertical-align: middle;
  display: flex;
  flex-direction: row;
  justify-content:space-between;
  margin-bottom: 5px;
  padding: 10px 10px 10px 10px;
}
.box .icon{
  width: 80px;
  line-height: 80px;
  padding-top: 10px;
}
.box .info{
  width: 100%;
  margin-left: 20px;
  text-overflow:ellipsis;
}
.box .info .title{
  width: 200px;
  padding-top: 10px;
  font-size: 13px;
  font-weight: bolder;
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;

}
.box .info .address{
  font-size: 12px;
  margin-top: 5px;
}
.box .info .score{
  margin-right: 10px;
}
.box .info .score label{
  color: #ff8080;
  font-weight: bolder;
  font-size: 12px;
  margin-left: 5px;
}
.box .info .station{
  font-size: 12px;
  margin-top: 5px;
  margin-right: 10px;
}

.box .info .distance{
  font-size: 12px;
  margin-top: 5px;
  margin-left: 5px;
  color: #ff8080;
}
.box .tools{
  width: 10px;
  height: 80px;
  line-height: 80px;
  vertical-align: middle;
  padding-right: 10px;
}

</style>

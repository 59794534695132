<template>
<div>
  <van-nav-bar left-text="返回" left-arrow title="Ｍ站信息" @click-left="goBack"/>
  <van-pull-refresh v-model="loading" @refresh="queryInfo">
    <van-swipe v-if="station.workImgs.length>0" class="my-swipe" :autoplay="3000" indicator-color="white">
      <van-swipe-item v-for="(img,index) in station.workImgs" v-bind:key="index">
          <van-image fit="contain" :src="img"/>
      </van-swipe-item>
    </van-swipe>
    <van-cell title="名称" :value="station.serviceCentreName" />
    <van-cell title="联系人" :value="station.userName" />
    <van-cell title="联系电话" :value="station.phone" />
    <van-cell title="工位数" :value="station.station" />
    <van-cell title="地址" :value="station.address" />
    <div id="allmap" ref="allmap"></div>
    <van-button block class="submit" type="info" @click="handleAppoint">预约维修</van-button>
  </van-pull-refresh>
  <van-popup v-model="showAppoint" closeable
             position="bottom" :style="{ height: '400px'}">
    <div class="title"><h2>预约信息</h2></div>
    <van-form>
      <van-field
          v-model="appoint.name"
          name="name"
          label="如何称呼"
          required
          placeholder="请填写您的姓名"
          :rules="[{ required: true }]"/>
      <van-field
          v-model="appoint.phone"
          name="phone"
          label="联系电话"
          required
          placeholder="请填写联系电话"
          :rules="[{ required: true }]"/>
      <van-field
          :value="appoint.plateNo"
          name="plateNo"
          label="车牌号"
          readonly
          placeholder="请选择车牌号"
         @click="showPlateNo = true"/>
      <van-popup v-model="showPlateNo" position="bottom" style="height: 300px">
        <van-picker
            show-toolbar
            :columns="vehicleList"
            @confirm="onPlateConfirm"
            @cancel="showPlateNo = false"
        />
      </van-popup>
      <van-field
          readonly
          clickable
          :value="appoint.time"
          label="到店时间"
          placeholder="点击选择时间"
          @click="showPicker = true"
      />
      <van-popup v-model="showPicker"
                 position="bottom" :style="{ height: '300px'}">
        <van-datetime-picker
            v-model="appoint.time"
            type="time"
            title="预计到店时间"
            :min-hour="minHour"
            :max-hour="maxHour"
            @confirm="onConfirmTime"
            @cancel="showPicker = false"
        />
      </van-popup>
      <div style="margin: 16px;">
        <van-button round block type="info" @click="appointSubmit">提交预约</van-button>
      </div>
    </van-form>

  </van-popup>
</div>
</template>

<script>
import {mInfo} from '@/api/station'
import {myVehicle} from '@/api/vehicle';
import {Dialog, Toast} from "vant";
import {appointSubmit} from "@/api/appoint";
import {getCurrentDate,getCurrentHour} from '@/utils/dateUtils'
export default {
  name: "info.vue",
  data(){
    return{
      showAppoint:false,
      showPicker: false,
      showPlateNo:false,
      loading:false,
      currentDate:'',
      id:'',
      minHour:8,
      maxHour:18,
      station:{
        id:'',
        serviceCentreName:'',
        address:'',
        userName:'',
        phone:'',
        station:0,
        lng:115.486191,
        lat:35.2401,
        mType:'',
        workImgs:[]
      },
      clng:115.486191,
      clat:35.2401,
      lng:115.486191,
      lat:35.2401,
      appoint:{
        name:'',
        phone:'',
        plateNo:'',
        time:''
      },
      vehicleList:[]
    }
  },
  mounted() {
    this.id = this.$route.query.id;
    this.currentDate = getCurrentDate();
    let hour = getCurrentHour();
    this.minHour = hour;
    this.queryInfo();
    this.mapInit();
    this.getMyVehicle();
  },
  methods:{
    goBack(){
      this.$router.go(-1);
    },
    mapInit () {
      var map = new window.BMapGL.Map(this.$refs.allmap);
      map.centerAndZoom(new window.BMapGL.Point(this.station.lng, this.station.lat), 12);
      var marker1 = new window.BMapGL.Marker(new window.BMapGL.Point(this.station.lng, this.station.lat));
      map.addOverlay(marker1);
      map.enableScrollWheelZoom(true);     //开启鼠标滚轮缩放
      var scaleCtrl = new window.BMapGL.ScaleControl();  // 添加比例尺控件
      map.addControl(scaleCtrl);
      var zoomCtrl = new window.BMapGL.ZoomControl();  // 添加比例尺控件
      map.addControl(zoomCtrl);
    },
    handleAppoint(){
      let _this = this;
      let user = localStorage.getItem("im_user");
      if(user == null){
        Dialog.confirm({
          title: '系统提示',
          message: '您还没有登录，是否立即登录',
        }).then(() => {
          _this.$router.push({
            path:"/login",
            query:{
              page: '/station/info'
            }
          })
        }).catch(() => {
        });
        return;
      }
      if(this.vehicleList.length == 0){
        Dialog.confirm({
          title: '系统提示',
          message: '请先绑定您的车辆',
        }).then(() => {
          _this.$router.push({
            path:"/vehicle/my",
            query:{
              page: '/station/info'
            }
          })
        }).catch(() => {
        });
        return;
      }
      this.showAppoint = true;
    },
    onConfirmTime(time){
      this.appoint.time = time;
      this.showPicker = false;
    },
    onPlateConfirm(data){
      this.appoint.plateNo = data;
      this.showPlateNo = false;
    },
    queryInfo(){
      mInfo(this.id).then(res => {
        if("200" != res.data.code){
          Toast({
            message: res.data.msg,
            icon:'warning-o',
            overlay:true,
            forbidClick: true,
          });
          return;
        }
        let data = res.data.data;
        this.station = {
          id:data.id,
          serviceCentreName:data.serviceCentreName,
          address:data.address,
          userName:data.userName,
          phone:data.phone,
          station:data.station,
          lng:parseFloat(data.lng),
          lat:parseFloat(data.lat),
          mType:data.mType
        };
        this.lng = this.station.lng;
        this.lat = this.station.lat;
        let sitePhoto = data.sitePhoto;
        let imgsStr = data.workImgs;
        let workImgs = [];
        workImgs.push(sitePhoto);
        if(imgsStr!=''){
          let imageList = imgsStr.split(',');
          for(let i=0;i<imageList.length;i++){
            workImgs.push(imageList[i]);
          }
        }
        this.station.workImgs = workImgs;
        this.mapInit();
      })
    },
    getMyVehicle(){
      this.vehicleList = [];
      myVehicle().then(res => {
        if("200" != res.data.code){
          Toast({
            message: res.data.msg,
            icon:'warning-o',
            overlay:true,
            forbidClick: true,
          });
          return;
        }
        let list = res.data.data;
        if(list){
          list.forEach(item => {
            this.vehicleList.push(item.plateNo);
          })
        }
      })
    },
    appointSubmit(){
      if(this.appoint.name == ''|| this.appoint.phone==''){
        Toast({
          message: "请完整填写您的联系方式",
          icon:'warning-o',
          overlay:true,
          forbidClick: true,
        });
        return;
      }
      Toast.loading({
        message: '正在提交...',
        overlay:true,
        forbidClick: true,
        duration:5000
      });
      let data = {
        userName:this.appoint.name,
        phone:this.appoint.phone,
        appointmentTime:this.currentDate + " " + this.appoint.time,
        vehicleServiceId:this.station.id,
        plateNo:this.appoint.plateNo
      }
      appointSubmit(data).then(res =>{
        Toast.clear();
        if("200"!=res.data.code){
          Toast({
            message: res.data.msg,
            icon:'warning-o',
            overlay:true,
            forbidClick: true,
          });
          return;
        }
        Toast({
          message: '预约提交成功',
          icon:'success',
          overlay:true,
          forbidClick: true
        });
        let _this = this;
        setTimeout(function(){
          _this.$router.push("/appoint/list");
        },2000)
      })
    }
  }
}
</script>

<style scoped>
.map{
  width: 100%;
  height: 200px;
  margin-bottom: 40px;
}
.submit{
  position: fixed;
  bottom:0px;
  z-index: 99;
}
.title{
  margin-left: 10px;
}
#allmap{
  width: 100%;
  height: 300px;
  margin-bottom: 50px;
}
.remark{
  padding-left: 20px;
  padding-right: 20px;
  font-size: 13px;
  color: #8a8a8a;
}
</style>

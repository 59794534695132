<template>
  <div>
    <van-sticky>
      <van-nav-bar left-arrow title="我的预约" @click-left="gotoBack"/>
    </van-sticky>
    <van-pull-refresh v-model="loading" @refresh="handleSearch">
      <van-list
          class="list"
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="handleSearch">
        <div class="box" v-for="a in aList" v-bind:key="a.id">
          <van-cell :title="a.plateNo" :value="a.appointmentTime">
            <template #title>
              <van-icon name="todo-list-o" color="#ff7733" size="20"/>
              <span class="custom-title" v-text="a.plateNo"></span>
              <van-tag type="danger" v-if="a.status==0">未接单</van-tag>
              <van-tag type="info" v-if="a.status==1">已接单</van-tag>
              <van-tag type="info" v-if="a.status==2">被拒绝</van-tag>
              <van-tag type="success" v-if="a.status==3">已到店</van-tag>
              <van-tag type="primary" v-if="a.status==4">已失效</van-tag>
            </template>
          </van-cell>
          <van-cell>
            <template #default>
              <label v-text="a.vehicleServiceName"></label>
            </template>
            <template #right-icon>
              <van-button v-if="a.status==0" type="danger" size="mini" icon="close" @click="handleCancel(a.id)">取消预约</van-button>
            </template>
          </van-cell>
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import {myAppoint,cancelAppointment} from '@/api/appoint';
import {myVehicle} from '@/api/vehicle';
import {Dialog, Toast} from "vant";
export default {
  name: "index.vue",
  data () {
    return {
      pageNumber:1,
      pageSize:10,
      plateNo:'',
      sort:'time',
      vehicleList:[
        {text:'全部车辆',value:''},
        {text:'鲁R12345',value:'鲁R12345'},
        {text:'鲁R54321',value:'鲁R54321'}
      ],
      sortList:[
        { text: '默认排序', value: 'time' },
        { text: '预约状态', value: 'status' },
        { text: '时间优先', value: 'time' },
      ],
      loading:false,
      finished: true,
      showAppoint:false,
      aList:[]
    }
  },
  mounted() {
    this.getMyVehicle();
    this.handleSearch();
  },
  methods: {
    handleSearch(){
      this.loading = false;
      this.pageNumber = 1;
      this.aList = [];
      this.queryMyAppoint();
    },
    gotoStation(){
      this.$router.push("/station/info")
    },
    gotoBack(){
      this.$router.go(-1);
    },
    getMyVehicle(){
      this.vehicleList = [];
      this.vehicleList.push({text:'全部车辆',value:''});
      myVehicle().then(res => {
        if("200" != res.data.code){
          Toast({
            message: res.data.msg,
            icon:'warning-o',
            overlay:true,
            forbidClick: true,
          });
          return;
        }
        let list = res.data.data;
        list.forEach(item =>{
          this.vehicleList.push({text:item.plateNo,value:item.plateNo})
        })
      })
    },
    queryMyAppoint(){
      let data = {
        plateNo:this.plateNo,
        sort:this.sort,
        pageNumber:this.pageNumber,
        pageSize: this.pageSize
      }
      myAppoint(data).then(res => {
        this.loading = false;
        if("200" != res.data.code){
          Toast({
            message: res.data.msg,
            icon:'warning-o',
            overlay:true,
            forbidClick: true,
          });
          return;
        }
        let total = res.data.data.total;
        let list = res.data.data.list;
        list.forEach(item =>{
          this.aList.push(item);
        })
        if(this.aList.length < total){
          this.finished = false;
          this.pageNumber += 1;
        }else{
          this.finished = true;
        }
      })
    },
    handleCancel(id){
      Dialog.confirm({
        title: '系统提示',
        confirmButtonColor:'#0099ff',
        message: '确定要取消本次预约吗?',
        theme: 'round-button',
      }).then(() => {
        cancelAppointment(id).then(res => {
          if("200" != res.data.code){
            Toast({
              message: res.data.msg,
              icon:'warning-o',
              overlay:true,
              forbidClick: true,
            });
            return;
          }
          Toast({
            message: '预约已取消',
            icon:'success',
            overlay:true,
            forbidClick: true
          });
          this.handleSearch();
        })
      }).catch(() => {
      });
    }

  }
}
</script>

<style>
.list{
  padding: 10px 5px 10px 5px;
  background-color: #f4f4f4;
}
.box{
  background-color: #FFFFFF;
  border-radius: 5px;
  vertical-align: middle;
  margin-bottom: 5px;
  padding: 10px 10px 10px 10px;
}
.custom-title{
  font-weight: bolder;
  font-size: 14px;
  margin-right: 5px;
}
</style>

<template>
<div>
  <van-sticky>
    <van-nav-bar left-arrow title="维修单详情" @click-left="gotoBack"/>
  </van-sticky>
  <van-pull-refresh v-model="loading" @refresh="getRepairInfo()">
    <van-tabs v-model="active">
      <van-tab title="维修单">
        <div class="order">
          <van-cell-group>
            <van-cell title="维修单号" :value="order.workOrderNumber" />
            <van-cell title="车牌号" :value="order.plateNo" />
            <van-cell title="维修站" :value="order.sitemName" />
            <van-cell title="检测站" :value="order.siteiName" />
            <van-cell title="开始时间" :value="order.startTime" />
            <van-cell title="完成时间" :value="order.endTime" />
            <van-cell title="维修时长" :value="order.repairTime" />
            <van-cell title="检测单号" :value="order.checkResultNumber" />
            <van-cell title="燃料类型" :value="order.fuelType" />
            <van-cell title="检测结果" >
              <template #default>
                <van-tag type="success " v-if="order.checkResult=='0'">合格</van-tag>
                <van-tag type="danger " v-else>不合格</van-tag>
              </template>
            </van-cell>
            <van-cell title="总费用" >
              <template #default>
                <label style="color: #ff8080; font-weight: bolder" v-text="order.price+' 元'"></label>
              </template>
            </van-cell>
          </van-cell-group>
          <van-cell-group title="维修项目" v-if="itemList.length==0">
            <van-cell title="暂无维修项目"></van-cell>
          </van-cell-group>
          <van-cell-group title="维修项目" v-else>
            <van-cell v-for="item in itemList" v-bind:key="item.id" :title="item.itemName" :value="item.orderPrice+'元'"></van-cell>
          </van-cell-group>
          <van-cell-group title="配件列表" v-if="accessoriesList.length==0">
            <van-cell title="暂无配件"></van-cell>
          </van-cell-group>
          <van-cell-group title="配件列表" v-else>
            <van-cell v-for="item in accessoriesList" v-bind:key="item.id" :title="item.accessoryName" :value="item.price+'元'"></van-cell>
          </van-cell-group>
        </div>
      </van-tab>
      <van-tab title="现场照片">
        <van-empty v-if="imageList.length==0" image="search" description="暂无现场照片" />
        <van-grid :border="false" :column-num="2" v-else>
          <van-grid-item v-for="(item,index) in imageList" v-bind:key="item.index">
            <van-image :src="item" @click="imagePreview(index)"/>
          </van-grid-item>
        </van-grid>
      </van-tab>
      <van-tab title="我的评价">
        <van-cell title="维修质量" :value="order.maintenanceQuality?order.maintenanceQuality+'分':'暂无内容'" />
        <van-cell title="收费标准" :value="order.charges?order.charges+'分':'暂无内容'" />
        <van-cell title="服务内容" :value="order.serviceContent?order.serviceContent+'分':'暂无内容'" />
        <div style="margin: 16px auto; width: 90%">
          <van-button round block type="info" @click="showEval">立即评价</van-button>
        </div>
      </van-tab>
    </van-tabs>
  </van-pull-refresh>
  <van-popup v-model="evalVisable" closeable
             position="bottom" :style="{ height: '300px'}">
    <div class="title"><h3>我的评价</h3></div>
    <van-form>
      <van-field name="rate" label="维修质量">
        <template #input>
          <van-rate
              allow-half color="#ffd21e"
              void-icon="star"
              void-color="#eee"
              v-model="eval.maintenanceQuality" />
        </template>
      </van-field>
      <van-field name="rate" label="服务质量">
        <template #input>
          <van-rate
              allow-half
              color="#ffd21e"
              void-icon="star"
              void-color="#eee"
              v-model="eval.serviceContent" />
        </template>
      </van-field>
      <van-field name="rate" label="收费标准">
        <template #input>
          <van-rate
              allow-half
              color="#ffd21e"
              void-icon="star"
              void-color="#eee"
              v-model="eval.charges" />
        </template>
      </van-field>
      <div style="margin: 16px;">
        <van-button round block type="info" @click="saveEval">提交</van-button>
      </div>
    </van-form>
  </van-popup>
</div>
</template>

<script>
import {getRepairInfo,evalSubmit} from "@/api/repair";
import {Toast,ImagePreview} from "vant";

export default {
  name: "info",
  data(){
    return{
      active:0,
      loading:false,
      evalVisable:false,
      id:'',
      itemList:[],
      accessoriesList:[],
      order:{},
      eval:{
        maintenanceQuality:3,
        serviceContent:3,
        charges:3
      },
      imageList:[]
    }
  },
  mounted() {
    Toast.clear();
    this.id = this.$route.query.id;
    this.getRepairInfo();
  },
  methods:{
    gotoBack(){
      this.$router.go(-1);
    },
    getRepairInfo(){
      this.loading = true;
      getRepairInfo(this.id).then(res=>{
        this.loading = false;
        if("200" != res.data.code){
          Toast({
            message: res.data.msg,
            icon:'warning-o',
            overlay:true,
            forbidClick: true,
          });
          return;
        }
        this.order = res.data.data.order;
        this.itemList = res.data.data.itemList;
        this.accessoriesList = res.data.data.accessoriesList;
        let imageList = res.data.data.order.imageUrl;
        if(imageList){
          this.imageList = imageList.split(',');
        }else{
          this.imageList = [];
        }
      })
    },
    showEval(){
      this.evalVisable = true;
    },
    imagePreview(index){
      ImagePreview({
        images: this.imageList,
        showIndex: true,
        closeable: true,
        startPosition: index
      });
    },
    saveEval(){
      let data = {
        maintenanceQuality:this.eval.maintenanceQuality,
        charges:this.eval.charges,
        serviceContent:this.eval.serviceContent,
        repairOrderId:this.order.id,
        siteId:this.order.siteId
      }
      evalSubmit(data).then(res =>{
        if("200" != res.data.code){
          Toast({
            message: res.data.msg,
            icon:'warning-o',
            overlay:true,
            forbidClick: true,
          });
          return;
        }
        Toast({
          message: '评价已提交',
          icon:'success',
          overlay:true,
          forbidClick: true,
        });
        this.order.maintenanceQuality = this.eval.maintenanceQuality;
        this.order.serviceContent = this.eval.serviceContent;
        this.order.charges = this.eval.charges;
        this.evalVisable = false;
      })
    }
  }
}
</script>

<style scoped>
.order{
  margin-bottom: 70px;
}

.title{
  margin-left: 10px;
}
</style>

<template>
  <div>
    <van-nav-bar left-text="返回" left-arrow title="绑定车辆" @click-left="goBack"/>
    <van-form>
      <van-field
          v-model="plateNo"
          name="brand"
          label="车牌号"
          maxlength="30"
          required
          placeholder="请输入车牌号"

      />
      <van-field
          v-model="frameNo"
          name="vin"
          label="车架号后6位"
          maxlength="6"
          required
          placeholder="请输入车架号"
      />
      <div style="margin: 16px;">
        <van-button round block type="info" @click="saveVehicle">立即绑定</van-button>
      </div>
    </van-form>
    <div class="remark">
      请如实填写您的车辆信息，以免车辆检测记录查询失败
    </div>
  </div>
</template>

<script>

import {Toast} from "vant";
import {addVehicle} from "@/api/vehicle";
export default {
  name: "addVehicle",
  data(){
    return{
      plateNo:'',
      frameNo:''
    }
  },
  methods:{
    goBack(){
      this.$router.go(-1);
    },
    saveVehicle(){
      if(this.plateNo == '' || this.frameNo == ''){
        Toast({
          message: '请输入完整的车辆信息',
          icon:'warning-o',
          overlay:true,
          forbidClick: true,
        });
        return;
      }
      Toast.loading({
        message: '正在提交...',
        overlay:true,
        forbidClick: true,
        duration:0
      });
      let vehicle = {
        plateNo:this.plateNo,
        frameNo:this.frameNo
      }
      addVehicle(vehicle).then(res => {
        Toast.clear();
        if("200"!=res.data.code){
          Toast({
            message: res.data.msg,
            icon:'warning-o',
            overlay:true,
            forbidClick: true,
          });
          return;
        }
        Toast({
          message: '添加成功',
          icon:'success',
          overlay:true,
          forbidClick: true
        });
        let _this = this;
        setTimeout(function(){
          _this.$router.push("/vehicle/my");
        },2000)
      })
    }
  }
}
</script>

<style scoped>
.remark{
  padding-left: 20px;
  padding-right: 20px;
  font-size: 13px;
  color: #8a8a8a;
}
</style>

<template>
  <div>
    <van-sticky>
      <van-nav-bar left-arrow title="我的维修单" @click-left="gotoBack"/>
      <van-dropdown-menu>
        <van-dropdown-item v-model="query.brand" :options="vehicleList" />
        <van-dropdown-item v-model="query.sort" :options="sortList" />
      </van-dropdown-menu>
    </van-sticky>
    <van-pull-refresh v-model="isLoading" @refresh="handleSearch">
      <van-empty v-if="rList.length == 0" image="search" description="您还没有维修单" />
      <van-list
          v-else
          class="list"
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="handleSearch">
        <div class="box" v-for="(r,index) in rList" v-bind:key="index" @click="gotoRepairInfo(r.id)">
          <van-cell :title="r.brand" is-link>
            <template #title>
              <van-icon name="balance-list-o" color="#ff7733" size="20"/>
              <span class="custom-title" v-text="r.plateNo"></span>
            </template>
            <template #default>
              <van-tag type="success " v-if="r.orderStatusString=='订单完成'">已完成</van-tag>
              <van-tag type="danger " v-else>维修中</van-tag>
            </template>
          </van-cell>
          <van-cell title="维修单号：" :value="r.workOrderNum"></van-cell>
          <van-cell title="开始时间：" :value="r.createTime"></van-cell>
          <van-cell title="完成时间：" :value="r.endTime"></van-cell>
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import {queryRepair} from "@/api/repair";
import {myVehicle} from '@/api/vehicle';
import {Toast} from "vant";
export default {
  name: "index.vue",
  data () {
    return {
      isLoading:false,
      query:{
        title:'',
        page:'1',
        size:10,
        brand:'',
        sort:'name'
      },
      vehicleList:[
        {text:'全部车辆',value:''}
      ],
      sortList:[
        { text: '默认排序', value: 'name' },
        { text: '预约状态', value: 'status' },
        { text: '时间优先', value: 'time' },
      ],
      loading:false,
      finished: true,
      showAppoint:false,
      rList:[]
    }
  },
  mounted() {
    this.getMyVehicle();
    this.handleSearch();
  },
  methods: {
    handleSearch(){
      this.isLoading = false;
      this.rList = [];
      this.queryRepair();
    },
    getMyVehicle(){
      myVehicle().then(res => {
        if("200" != res.data.code){
          Toast({
            message: res.data.msg,
            icon:'warning-o',
            overlay:true,
            forbidClick: true,
          });
          return;
        }
        let list = res.data.data;
        if(list){
          list.forEach(item => {
            this.vehicleList.push(
                {text:item.plateNo,value:item.plateNo}
            )
          })
        }
      })
    },
    queryRepair(){
      queryRepair(this.query).then(res =>{
        if("200" != res.data.code){
          Toast({
            message: res.data.msg,
            icon:'warning-o',
            overlay:true,
            forbidClick: true,
          });
          return;
        }
        let total = res.data.data.total;
        let list = res.data.data.list;
        list.forEach(item =>{
          this.rList.push(item);
        })
        if(this.rList.length < total){
          this.finished = false;
          this.pageNum += 1;
        }else{
          this.finished = true;
        }
      })
    },
    gotoStation(){
      this.$router.push("/station/info")
    },
    gotoBack(){
      this.$router.go(-1);
    },
    gotoRepairInfo(id){
      this.$router.push({
        path:"/repair/info",
        query:{
          id: id
        }
      })
    }

  }
}
</script>

<style scoped>
.list{
  padding: 10px 5px 10px 5px;
  background-color: #f4f4f4;
}
.box{
  height: 150px;
  background-color: #FFFFFF;
  border-radius: 5px;
  margin-bottom: 5px;
  display: flex;
  flex-direction: column;
}


</style>

<template>
  <div>
    <van-nav-bar left-text="返回" left-arrow title="我要预约" @click-left="goBack"/>
    <van-form>
      <van-field
          v-model="phone"
          name="phone"
          label="联系电话"
          required
          placeholder="请填写联系电话"
          :rules="[{ required: true }]"
      />
      <div style="margin: 16px;">
        <van-button round block type="info" native-type="submit">提交预约</van-button>
      </div>
    </van-form>
    <div class="remark">
      请如实填写您的车辆信息，以免车辆检测记录查询失败
    </div>
  </div>
</template>

<script>
export default {
  name: "add",
  data(){
    return{
      phone:''
    }
  },
  methods:{
    goBack(){
      this.$router.go(-1);
    }
  }
}
</script>

<style scoped>
.form{
  padding-left: 20px;
  padding-right: 20px;
}
.remark{
  padding-left: 20px;
  padding-right: 20px;
  font-size: 13px;
  color: #8a8a8a;
}
</style>

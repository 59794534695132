<template>
  <div class="bg">
    <div class="head">
      <div class="hello">Hello!</div>
      <div class="title">欢迎登录IM机动车尾气治理平台</div>
    </div>
    <div class="box">
      <van-form class="form">
        <van-field
            label-width="60"
            v-model="phone"
            name="phone"
            clearable
            label="手机号"
            placeholder="请输入手机号"
        />
        <van-field
            label-width="60"
            v-model="password"
            type="password"
            name="密码"
            label="密码"
            clearable
            placeholder="请输入密码"
        />
        <van-button style="margin-top: 30px" round block type="info" @click="handleLogin">登录</van-button>
        <div class="register" >
          <span @click="gotoRegister">没有账号，点此注册</span>
          <span @click="gotoForget">忘记密码</span>
        </div>
      </van-form>
    </div>

<!--    <div class="bottom">-->
<!--      <van-image class="bm" :src="require('../../assets/login_bottom.png')"/>-->
<!--    </div>-->
  </div>
</template>

<script>
import {getUserInfo, login} from "@/api/user";
import {Toast} from "vant";
export default {
  name: "login.vue",
  data(){
    return{
      phone: '',
      password: '',
      page:''
    }
  },
  mounted() {
    this.page = this.$route.query.page;
  },
  methods:{
    gotoRegister(){
      this.$router.push("/register");
    },
    gotoForget(){
      this.$router.push("/forget");
    },
    handleLogin(){
      if(this.phone == '' || this.password == ''){
        Toast({
          message: '登录信息不完整',
          icon:'warning-o',
          overlay:true,
          forbidClick: true,
        });
        return;
      }
      Toast.loading({
        message: '正在登录...',
        forbidClick: true,
        loadingType: 'spinner'
      });
      let data = {
        appPhone:this.phone,
        password:this.password
      }
      login(data).then(res => {
        Toast.clear();
        if("200" != res.data.code){
          Toast({
            message: res.data.msg,
            icon:'warning-o',
            overlay:true,
            forbidClick: true,
          });
          return;
        }
        localStorage.setItem("im_token",res.data.data.token);
        this.getUserInfo();
      })
    },
    getUserInfo(){
      Toast.loading({
        message: '正在获取用户信息...',
        forbidClick: true,
        loadingType: 'spinner'
      });
      getUserInfo().then(res => {
        Toast.clear();
        if("200" != res.data.code){
          Toast({
            message: '获取用户信息失败',
            icon:'warning-o',
            overlay:true,
            forbidClick: true,
          });
          return;
        }
        let data = res.data.data;
        let user = {
          userId:data.userId,
          username:data.username,
          totalPrice:data.totalPrice,
          userPhone:data.userPhone,
          repairCount:data.repairCount,
        }
        localStorage.setItem("im_user",JSON.stringify(user));
        Toast.loading({
          message: '登录成功,正在跳转',
          overlay:true,
          forbidClick: true,
        });
        let _this = this;
        let page = "/member";
        if(this.page){
          page = this.page;
        }
        setTimeout(function(){
          _this.$router.push(page);
        },2000)
      })
    }
  }
}
</script>

<style scoped>
.bg {
  width: 100%;
  height: 600px;
  background: url('../../assets/login_bg.png') no-repeat;
  background-size: 100% auto;
  z-index: -99;
  padding-top:100px;
}
.head{
  padding: 10px 10px 10px 10px;
}
.head .hello{
  padding-left: 30px;
  font-size: 30px;
  line-height: 50px;
  font-weight: bolder;
  color: #0099ff;
}
.head .title{
  padding-left: 30px;
  font-size: 20px;
  font-weight: bolder;
  color: #0099ff;
}
.box{
  width: 90%;
  height: auto;
  text-align: center;
}
.box .form{
  margin: 10px auto;
}
.bottom{
  position: absolute;
  bottom: 0;
}
.bottom .bm{
  float: right;
}
.register{
  color: darkgrey;
  font-size: 14px;
  margin-top: 10px;
  line-height: 30px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
</style>
